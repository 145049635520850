import React, { useRef, useEffect } from "react"
import {useWindowSize} from 'react-use'

import ChartComponent from "./chartComponent"
import departements from "./dataset/dep.json"
import donnees from "./dataset/terr.json"

export default function Chart({ codzone }) {
  const d3Container = useRef(null)
  const {width : windowWidth, height : windowHeight} = useWindowSize();
  useEffect(() => {
  
    if (d3Container && d3Container.current && codzone) {
      let itemDep = undefined
      departements.forEach(dep => {
        if (dep.code === codzone.substring(0, 2)) {
          itemDep = dep
        }
      })

      let itemCom = undefined
      donnees.forEach(item => {
        if (item.com === codzone) {
          itemCom = item
        }
      })
      let chart = new ChartComponent({
        ref: d3Container.current,
        width: windowWidth*0.95,
        height:360,
        france: {
          nom: "France",
          nvd1: 890,
          nvd9: 3133,
          nvq2: 1697,
          flncttgi: 0.2919,
          tp60: 14.690901,
        },
        departement: { ...itemDep },
        commune: { ...itemCom },
      })
      chart.init()
    }
  }, [d3Container,codzone,windowWidth, windowHeight])

  

  return <div  ref={d3Container} ></div>
}
