import React, { useState, useRef } from "react"

import { Flex, Text, Link, Box } from "@chakra-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"

import imgFragiliteEnfant from "../images/revenus.svg"

import Home from "../components/nivie/home"
import Select from "../components/nivie/select"
import Chart from "../components/nivie/chart"
import ModalMethodo from "../components/nivie/ModalMethodo"

const ComparateurNiveauxDeVie = () => {
  const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)

  return (
    <Layout
      title="Niveaux de vie et précarité : où en est ma commune ?"
      headerContainer={headerContainer}
      img={imgFragiliteEnfant}
    >
      <SEO title="Niveaux de vie et précarité : où en est ma commune ?" />
      <Home />
      <Select
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
      <Chart codzone={codzone} />

      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="xs" m={4}>
          Sources :{" "}
          <Link href="http://www.le-compas.fr" isExternal>
            Compas©
          </Link>
          , Filosofi 2020
        </Text>
        <ModalMethodo codzone={codzone} />
      </Flex>

      <Box mb={10}></Box>
    </Layout>
  )
}

export default ComparateurNiveauxDeVie
