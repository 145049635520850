import React from "react"

import { Flex, Text, Image, Stack, Link, Icon, Box } from "@chakra-ui/core"

import imgObsInegalites from "../../images/logo-obs.png"
import imgGazette from "../../images/logo-gazette.png"

import imgSonar from "../../images/Sonar_logo_V4-250x131.png"
import imgLogo from "../../images/logo.png"

export default function Home({}) {
  return (
    <Flex flexDirection="row" mr={10} ml={10} mb={15}>
      <Box flexBasis="70%" pr={10}>
        <Stack spacing={4}>
          <Text>
            <Link href="http://www.lecompas.fr/" isExternal>
              Le Compas
              <Icon name="external-link" mx="2px" />
            </Link>
            , bureau d'études spécialisé dans l'analyse des données sociales,
            participe au débat public sur la situation des territoires.
          </Text>
          <Text>
            Il a ainsi élaboré ce comparateur accessible à tous en ligne, en
            partenariat avec{" "}
            <Link href="http://www.lagazettedescommunes.com/" isExternal>
              la Gazette des communes
              <Icon name="external-link" mx="2px" />
            </Link>{" "}
            et{" "}
            <Link href="http://www.inegalites.fr/" isExternal>
              l’Observatoire des inégalités
              <Icon name="external-link" mx="2px" />
            </Link>{" "}
            . Cet outil permet à chacun, acteur professionnel ou citoyen, de
            disposer d'information sur les niveaux de vie de sa commune.
          </Text>
          <Text>
            Ces données sont tirées de l'outil en ligne{" "}
            <Link href="http://cabestan.lecompas.fr" isExternal>
              Cabestan
              <Icon name="external-link" mx="2px" />
            </Link>{" "}
            apportant plus de détail dans la répartition des niveaux de vie pour
            le niveau communal et infra communal.
          </Text>
        </Stack>
      </Box>
      <Flex flexBasis="30%" flexDirection="column" flex={6} alignItems="center">
        <Text mb={4}>en partenariat avec </Text>
        <Flex>
          <Link href="http://www.inegalites.fr/" isExternal m={4}>
            <Image
              src={imgObsInegalites}
              alt="logo observatoire des inégalités"
              htmlWidth="150px"
            />
          </Link>
          <Link href="http://www.lagazettedescommunes.com/" isExternal m={4}>
            <Image
              src={imgGazette}
              alt="logo gazette des communes"
              htmlWidth="150px"
            />
          </Link>
        </Flex>
        <Text mt={10} mb={2}>
          Des indicateurs exclusifs
        </Text>
        <Box
          mx={5}
          as="a"
          rel="noopener noreferrer"
          title="Sonar"
          href="http://www.lecompas.fr"
          target="_blank"
        >
          <img
            src={imgLogo}
            width={"50%"}
            alt="Compas"
            style={{ margin: "auto" }}
          ></img>
        </Box>
      </Flex>
    </Flex>
  )
}
