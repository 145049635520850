import * as d3 from "d3"

function calculeMin(valeurs, getFunction) {
  let vmin = Number.MAX_VALUE
  if (valeurs) {
    valeurs.forEach(item => {
      let valeur = item
      if (getFunction) {
        valeur = getFunction(item)
      }
      if (!isNaN(valeur) && valeur && valeur != "") {
        valeur = +valeur
        if (valeur < vmin) {
          vmin = valeur
        }
      }
    })
  }
  return vmin
}

function calculeMax(valeurs, getFunction) {
  let vmax = Number.MIN_VALUE
  if (valeurs) {
    valeurs.forEach(item => {
      let valeur = item
      if (getFunction) {
        valeur = getFunction(item)
      }
      if (!isNaN(valeur) && valeur && valeur != "") {
        valeur = +valeur
        if (valeur > vmax) {
          vmax = valeur
        }
      }
    })
  }
  return vmax
}

export default class Chart {
  constructor(options) {
    this.ref = options.ref

    this.svgWidth = options.width || "600px"
    this.svgHheight = options.height || "360px"
    this.viewportWidth = options.width || 600
    this.viewportHeight = options.height || 360
    this.innerX = 0
    this.innerY = 0
    this.legendWith = this.viewportWidth / 8
    this.innerChartY = (2 * this.viewportHeight) / 10
    this.innerChartHeight = (7 * this.viewportHeight) / 10
    this.innerChartWidth = this.viewportWidth - this.legendWith
    this.barWidth = 4
    this.barColor = "rgb(50,50,50)"
    this.lineColor = "rgb(100,100,100)"
    this.lineStrokeWidth = 2
    this.circles = {
      r: 12,
      opacity: 0.9,
      stroke: "#646464",
    }
    this.colors = {
      nvd9: "#24d900",
      nvq2: "#efffcd",
      nvd1: "#ff8800",
      flncttgi: "#dd3333",
      tp60: "#aa3399",
    }

    // console.log(this)
    //
    this.france = options.france
    this.depart = options.departement
    this.comm = options.commune

    this.vmin = calculeMin([
      this.france.nvd1,
      this.france.nvq2,
      this.france.nvd9,
      this.depart.nvd1,
      this.depart.nvq2,
      this.depart.nvd9,
      this.comm.nvd1,
      this.comm.nvq2,
      this.comm.nvd9,
    ])

    this.vmax = calculeMax([
      this.france.nvd1,
      this.france.nvq2,
      this.france.nvd9,
      this.depart.nvd1,
      this.depart.nvq2,
      this.depart.nvd9,
      this.comm.nvd1,
      this.comm.nvq2,
      this.comm.nvd9,
    ])

    this.gini = {
      visible:
        !isNaN(this.comm.flncttgi) &&
        this.comm.flncttgi &&
        this.comm.flncttgi != "",
      x: 0,
      vmin: calculeMin([
        this.france.flncttgi,
        this.depart.flncttgi,
        this.comm.flncttgi,
      ]),
      vmax: calculeMax([
        this.france.flncttgi,
        this.depart.flncttgi,
        this.comm.flncttgi,
      ]),
    }

    this.tp60 = {
      visible: !isNaN(this.comm.tp60) && this.comm.tp60 && this.comm.tp60 != "",
      x: 0,
      vmin: calculeMin([this.france.tp60, this.depart.tp60, this.comm.tp60]),
      vmax: calculeMax([this.france.tp60, this.depart.tp60, this.comm.tp60]),
    }

    //console.log("nvd1", this.france.nvd1, this.depart.nvd1, this.comm.nvd1);
    //console.log("nvq2", this.france.nvq2, this.depart.nvq2, this.comm.nvq2);
    //console.log("nvd9", this.france.nvd9, this.depart.nvd9, this.comm.nvd9);
    //console.log("gini", this.france.flncttgi, this.depart.flncttgi, this.comm.flncttgi);
    //console.log("vmin", this.vmin);
    //console.log("vmax", this.vmax);
    // console.log("tp60", this.tp60);
  }

  /**
   * changement de repère pour le svg
   * @param x
   * @returns {*}
   */
  computeForSVGX(x) {
    return x
  }

  computeForSVGY(y) {
    return this.viewportHeight - y
  }

  addRect(el, x, y, w, h, attrs) {
    let r = el
      .append("rect")
      .attr("x", this.computeForSVGX(x))
      .attr("y", this.computeForSVGY(y))
      .attr("width", w)
      .attr("height", h)
    if (attrs) {
      for (let attr in attrs) {
        r.attr(attr, attrs[attr])
      }
    }
    return r
  }

  addLine(el, x1, y1, x2, y2, attrs) {
    let r = el
      .append("line")
      .attr("x1", this.computeForSVGX(x1))
      .attr("y1", this.computeForSVGY(y1))
      .attr("x2", this.computeForSVGX(x2))
      .attr("y2", this.computeForSVGY(y2))
    if (attrs) {
      for (let attr in attrs) {
        r.attr(attr, attrs[attr])
      }
    }
    return r
  }

  addCircle(el, cx, cy, r, attrs) {
    let e = el
      .append("circle")
      .attr("cx", this.computeForSVGX(cx))
      .attr("cy", this.computeForSVGY(cy))
      .attr("r", r)
    if (attrs) {
      for (let attr in attrs) {
        e.attr(attr, attrs[attr])
      }
    }
    return e
  }

  addText(el, x, y, txt, attrs) {
    let r = el
      .append("text")
      .attr("y", this.computeForSVGY(y))
      .attr("x", this.computeForSVGX(x))
      .text(txt)
    if (attrs) {
      for (let attr in attrs) {
        r.attr(attr, attrs[attr])
      }
    }
    return r
  }

  addTextTSpan(el, x, y, txt1, txt2, dy, attrs) {
    let r = el.append("text").attr("y", this.computeForSVGY(y))
    r.append("tspan")
      .attr("x", this.computeForSVGX(x))
      .attr("dy", dy)
      .text(txt1)
    r.append("tspan")
      .attr("x", this.computeForSVGX(x))
      .attr("dy", dy)
      .text(txt2)
    if (attrs) {
      for (let attr in attrs) {
        r.attr(attr, attrs[attr])
      }
    }
    return r
  }

  drawInitialCircle(x, y, scale, color) {
    return this.addCircle(this.svgChart, x, scale(y), this.circles.r, {
      fill: color,
      opacity: 0,
      stroke: "none",
    })
  }

  animateCircle(circle) {
    return circle
      .transition()
      .delay(0)
      .duration(1000)
      .attr("opacity", this.circles.opacity)
      .attr("stroke", this.circles.stroke)
  }

  animateCircle2(circle, x, ease) {
    return this.animateCircle(circle).attr("cx", x)
    //return d3.easeBounce(this.animateCircle(circle)).attr("cx", x);
  }

  animateText(text) {
    return text
      .attr("opacity", "0")
      .transition()
      .delay(1000)
      .duration(1000)
      .attr("opacity", 1.0)
  }

  /**
   * affiche le graphique initial : les 4 barres
   */
  initInnerChart() {
    //let innerChart = this.addRect(this.svgChart, this.legendWith, this.innerChartY, this.innerChartWidth, this.innerChartHeight, {fill:"blue"});
    let giniWidth = (20 * this.innerChartWidth) / 100
    let barsOffset = (20 * this.innerChartWidth) / 100
    let tp60Width = (15 * this.innerChartWidth) / 100

    // barre France
    this.france.x = this.legendWith
    this.addLine(
      this.svgChart,
      this.france.x,
      this.innerChartY,
      this.france.x,
      this.innerChartY + this.innerChartHeight,
      {
        "stroke-width": this.barWidth,
        stroke: this.barColor,
      }
    )

    // barre Dep
    this.depart.x = this.legendWith + barsOffset
    this.addLine(
      this.svgChart,
      this.depart.x,
      this.innerChartY,
      this.depart.x,
      this.innerChartY + this.innerChartHeight,
      {
        "stroke-width": this.barWidth,
        stroke: this.barColor,
      }
    )

    // barre Comm
    this.comm.x = this.legendWith + barsOffset * 2
    this.addLine(
      this.svgChart,
      this.comm.x,
      this.innerChartY,
      this.comm.x,
      this.innerChartY + this.innerChartHeight,
      {
        "stroke-width": this.barWidth,
        stroke: this.barColor,
      }
    )

    // bare Gini
    if (this.gini.visible) {
      this.gini.x = this.legendWith + barsOffset * 3
      this.addLine(
        this.svgChart,
        this.gini.x,
        this.innerChartY,
        this.gini.x,
        this.innerChartY + this.innerChartHeight,
        {
          "stroke-width": this.barWidth,
          stroke: this.barColor,
        }
      )
    }

    // bare TP60
    if (this.tp60.visible) {
      this.tp60.x = this.legendWith + this.innerChartWidth - tp60Width
      this.addLine(
        this.svgChart,
        this.tp60.x,
        this.innerChartY,
        this.tp60.x,
        this.innerChartY + this.innerChartHeight,
        {
          "stroke-width": this.barWidth,
          stroke: this.barColor,
        }
      )
    }
  }

  animate() {
    let scale = d3
      .scaleLinear()
      .domain([this.vmin, this.vmax])
      .range([
        this.innerChartY + (2 * (2 * this.circles.r)) / 3,
        this.innerChartY +
          this.innerChartHeight -
          (2 * (2 * this.circles.r)) / 3,
      ])
    let scaleGini = d3
      .scaleLinear()
      .domain([this.gini.vmin, this.gini.vmax])
      .range([
        this.innerChartY + (2 * this.circles.r) / 3,
        this.innerChartY + this.innerChartHeight - (2 * this.circles.r) / 3,
      ])
    let scaleTp60 = d3
      .scaleLinear()
      .domain([this.tp60.vmin, this.tp60.vmax])
      .range([
        this.innerChartY + (2 * this.circles.r) / 3,
        this.innerChartY + this.innerChartHeight - (2 * this.circles.r) / 3,
      ])

    // calcule des ordonnées des cercles pour les légendes
    let yLegended1 = scale(this.france.nvd1)
    let yLegended9 = scale(this.france.nvd9)
    let yLegendeq2 = scale(this.france.nvq2)

    // la légende
    let legendeAttrs = {
      "text-anchor": "end",
      "font-size": "12px",
    }
    this.svgLegendeD1 = this.addText(
      this.svgChart,
      -200,
      yLegended1,
      "10% les plus pauvres",
      legendeAttrs
    )
      .transition()
      .delay(650)
      .duration(350)
      .attr("x", this.computeForSVGX(this.legendWith - 15))
    this.svgLegendeD9 = this.addText(
      this.svgChart,
      -200,
      yLegended9 - this.circles.r / 2,
      "10% les plus riches",
      legendeAttrs
    )
      .transition()
      .delay(650)
      .duration(350)
      .attr("x", this.computeForSVGX(this.legendWith - 15))
    this.svgLegendeD9 = this.addText(
      this.svgChart,
      -200,
      yLegendeq2 - this.circles.r / 2,
      "Niveau de vie médian",
      legendeAttrs
    )
      .transition()
      .delay(650)
      .duration(350)
      .attr("x", this.computeForSVGX(this.legendWith - 13))

    let legendeNomsAttrs = {
      "text-anchor": "middle",
      "font-size": "12px",
    }
    this.svgLegendeFrance = this.addText(
      this.svgChart,
      this.france.x,
      this.innerChartY - 30,
      this.france.nom,
      legendeNomsAttrs
    )
    this.svgLegendeDepart = this.addText(
      this.svgChart,
      this.depart.x,
      this.innerChartY - 30,
      this.depart.nom,
      legendeNomsAttrs
    )
    this.svgLegendeComm = this.addText(
      this.svgChart,
      this.comm.x,
      this.innerChartY - 30,
      this.comm.nom,
      legendeNomsAttrs
    )
    if (this.gini.visible) {
      this.svgLegendeGini = this.addText(
        this.svgChart,
        this.gini.x,
        this.innerChartY - 30,
        "Indice d'inégalité",
        legendeNomsAttrs
      )
      this.addText(
        this.svgChart,
        this.gini.x,
        this.innerChartY - 50,
        "(Gini)",
        { "text-anchor": "middle" }
      )
    }
    if (this.tp60.visible) {
      this.svgLegendeTp60 = this.addText(
        this.svgChart,
        this.tp60.x,
        this.innerChartY - 30,
        "Taux de pauvreté",
        legendeNomsAttrs
      )
    }

    // afficher les cercles pour la France
    this.animateCircle2(
      this.drawInitialCircle(
        -this.circles.r * 2,
        this.france.nvd1,
        scale,
        this.colors.nvd1
      ),
      this.france.x,
      "bounce"
    )
    this.animateCircle2(
      this.drawInitialCircle(
        -this.circles.r * 2,
        this.france.nvq2,
        scale,
        this.colors.nvq2
      ),
      this.france.x,
      "bounce"
    )
    this.animateCircle2(
      this.drawInitialCircle(
        -this.circles.r * 2,
        this.france.nvd9,
        scale,
        this.colors.nvd9
      ),
      this.france.x,
      "bounce"
    )

    // afficher les cercles pour le département
    this.animateCircle2(
      this.drawInitialCircle(
        this.depart.x - this.circles.r * 4,
        this.depart.nvd1,
        scale,
        this.colors.nvd1
      ),
      this.depart.x,
      "bounce"
    )
    this.animateCircle2(
      this.drawInitialCircle(
        this.depart.x - this.circles.r * 4,
        this.depart.nvq2,
        scale,
        this.colors.nvq2
      ),
      this.depart.x,
      "bounce"
    )
    this.animateCircle2(
      this.drawInitialCircle(
        this.depart.x - this.circles.r * 4,
        this.depart.nvd9,
        scale,
        this.colors.nvd9
      ),
      this.depart.x,
      "bounce"
    )

    // afficher les cercles pour la commune
    if (this.comm.nvd1) {
      this.animateCircle(
        this.drawInitialCircle(
          this.comm.x,
          this.comm.nvd1,
          scale,
          this.colors.nvd1
        )
      )
    }
    if (this.comm.nvq2) {
      this.animateCircle(
        this.drawInitialCircle(
          this.comm.x,
          this.comm.nvq2,
          scale,
          this.colors.nvq2
        )
      )
    }
    if (this.comm.nvd9) {
      this.animateCircle(
        this.drawInitialCircle(
          this.comm.x,
          this.comm.nvd9,
          scale,
          this.colors.nvd9
        )
      )
    }

    // afficher les cercles pour l'indice de gini
    if (this.gini.visible) {
      if (this.france.flncttgi) {
        this.animateCircle(
          this.drawInitialCircle(
            this.gini.x,
            this.france.flncttgi,
            scaleGini,
            this.colors.flncttgi
          )
        )
      }
      if (this.depart.flncttgi) {
        this.animateCircle(
          this.drawInitialCircle(
            this.gini.x,
            this.depart.flncttgi,
            scaleGini,
            this.colors.flncttgi
          )
        )
      }
      if (this.comm.flncttgi) {
        this.animateCircle(
          this.drawInitialCircle(
            this.gini.x,
            this.comm.flncttgi,
            scaleGini,
            this.colors.flncttgi
          )
        )
      }
    }

    // afficher les cercles pour le tp60
    if (this.tp60.visible) {
      if (this.france.tp60) {
        this.animateCircle(
          this.drawInitialCircle(
            this.tp60.x,
            this.france.tp60,
            scaleTp60,
            this.colors.tp60
          )
        )
      }
      if (this.depart.tp60) {
        this.animateCircle(
          this.drawInitialCircle(
            this.tp60.x,
            this.depart.tp60,
            scaleTp60,
            this.colors.tp60
          )
        )
      }
      if (this.comm.tp60) {
        this.animateCircle(
          this.drawInitialCircle(
            this.tp60.x,
            this.comm.tp60,
            scaleTp60,
            this.colors.tp60
          )
        )
      }
    }

    // afficher les lignes !
    // construction du tableau des coordonnées
    let lineGenerator = d3
      .line()
      .x(d => this.computeForSVGX(d.x))
      .y(d => this.computeForSVGY(scale(d.y))) //.interpolate("linear");
    let tabd1 = [
      { x: this.france.x, y: this.france.nvd1 },
      { x: this.depart.x, y: this.depart.nvd1 },
    ]
    if (this.comm.nvd1) {
      tabd1.push({
        x: this.comm.x,
        y: this.comm.nvd1,
      })
    }
    let tabq2 = [
      { x: this.france.x, y: this.france.nvq2 },
      { x: this.depart.x, y: this.depart.nvq2 },
    ]
    if (this.comm.nvq2) {
      tabq2.push({
        x: this.comm.x,
        y: this.comm.nvq2,
      })
    }
    let tabd9 = [
      { x: this.france.x, y: this.france.nvd9 },
      { x: this.depart.x, y: this.depart.nvd9 },
    ]
    if (this.comm.nvd9) {
      tabd9.push({
        x: this.comm.x,
        y: this.comm.nvd9,
      })
    }
    this.svgChart
      .append("path")
      .attr("d", lineGenerator(tabd1))
      .attr("stroke", "none")
      .attr("stroke-width", this.lineStrokeWidth)
      .attr("fill", "none")
      .transition()
      .delay(1000)
      .duration(350)
      .attr("stroke", this.lineColor)
    this.svgChart
      .append("path")
      .attr("d", lineGenerator(tabq2))
      .attr("stroke", "none")
      .attr("stroke-width", this.lineStrokeWidth)
      .attr("fill", "none")
      .transition()
      .delay(1000)
      .duration(350)
      .attr("stroke", this.lineColor)
    this.svgChart
      .append("path")
      .attr("d", lineGenerator(tabd9))
      .attr("stroke", "none")
      .attr("stroke-width", this.lineStrokeWidth)
      .attr("fill", "none")
      .transition()
      .delay(1000)
      .duration(350)
      .attr("stroke", this.lineColor)

    // afficher les valeurs !
    let dx = this.circles.r
    let dy = this.circles.r
    let legendeTexteValeurs = { "text-anchor": "start", "font-size": "12px" }

    this.animateText(
      this.addText(
        this.svgChart,
        this.france.x + dx,
        yLegended1 + dy,
        this.france.nvd1 + " €",
        legendeTexteValeurs
      )
    )
    this.animateText(
      this.addText(
        this.svgChart,
        this.france.x + dx,
        yLegendeq2 + dy,
        this.france.nvq2 + " €",
        legendeTexteValeurs
      )
    )
    this.animateText(
      this.addText(
        this.svgChart,
        this.france.x + dx,
        yLegended9 + dy,
        this.france.nvd9 + " €",
        legendeTexteValeurs
      )
    )

    this.animateText(
      this.addText(
        this.svgChart,
        this.depart.x + dx,
        scale(this.depart.nvd1) + dy,
        this.depart.nvd1 + " €",
        legendeTexteValeurs
      )
    )
    this.animateText(
      this.addText(
        this.svgChart,
        this.depart.x + dx,
        scale(this.depart.nvq2) + dy,
        this.depart.nvq2 + " €",
        legendeTexteValeurs
      )
    )
    this.animateText(
      this.addText(
        this.svgChart,
        this.depart.x + dx,
        scale(this.depart.nvd9) + dy,
        this.depart.nvd9 + " €",
        legendeTexteValeurs
      )
    )
    if (this.comm.nvd1) {
      this.animateText(
        this.addText(
          this.svgChart,
          this.comm.x + dx,
          scale(this.comm.nvd1) + dy,
          this.comm.nvd1 + " €",
          legendeTexteValeurs
        )
      )
    }
    if (this.comm.nvq2) {
      this.animateText(
        this.addText(
          this.svgChart,
          this.comm.x + dx,
          scale(this.comm.nvq2) + dy,
          this.comm.nvq2 + " €",
          legendeTexteValeurs
        )
      )
    }
    if (this.comm.nvd1) {
      this.animateText(
        this.addText(
          this.svgChart,
          this.comm.x + dx,
          scale(this.comm.nvd9) + dy,
          this.comm.nvd9 + " €",
          legendeTexteValeurs
        )
      )
    }

    if (this.gini.visible) {
      let legendeTexteGini = { ...legendeTexteValeurs, "text-anchor": "start" }
      // calcule de l'ordre d'affichage
      let dyDepart = 0
      let dyFrance = 0
      let dyComm = 0
      if (this.comm.flncttgi) {
        if (
          +this.france.flncttgi > +this.comm.flncttgi &&
          +this.france.flncttgi > +this.depart.flncttgi
        ) {
          dyFrance = -20
        }
        if (
          +this.comm.flncttgi > +this.france.flncttgi &&
          +this.comm.flncttgi > +this.depart.flncttgi
        ) {
          dyComm = -20
        }
        if (
          +this.depart.flncttgi > +this.france.flncttgi &&
          +this.depart.flncttgi > +this.comm.flncttgi
        ) {
          dyDepart = -20
        }
        if (
          +this.france.flncttgi < +this.comm.flncttgi &&
          +this.france.flncttgi < +this.depart.flncttgi
        ) {
          dyFrance = 20
        }
        if (
          +this.comm.flncttgi < +this.france.flncttgi &&
          +this.comm.flncttgi < +this.depart.flncttgi
        ) {
          dyComm = 20
        }
        if (
          +this.depart.flncttgi < +this.france.flncttgi &&
          +this.depart.flncttgi < +this.comm.flncttgi
        ) {
          dyDepart = 20
        }
      }

      if (this.france.flncttgi) {
        this.animateText(
          this.addTextTSpan(
            this.svgChart,
            this.gini.x + dx + 4,
            scaleGini(this.france.flncttgi) + dy + 0,
            this.france.nom + " :",
            Math.trunc(this.france.flncttgi * 100) / 100,
            15,
            legendeTexteGini
          )
        )
      }
      if (this.depart.flncttgi) {
        this.animateText(
          this.addTextTSpan(
            this.svgChart,
            this.gini.x + dx + 4,
            scaleGini(this.depart.flncttgi) + dy + 0,
            this.depart.nom + " :",
            Math.trunc(this.depart.flncttgi * 100) / 100,
            15,
            legendeTexteGini
          )
        )
      }
      if (this.comm.flncttgi) {
        this.animateText(
          this.addTextTSpan(
            this.svgChart,
            this.gini.x + dx + 4,
            scaleGini(this.comm.flncttgi) + dy + 0,
            this.comm.nom + " :",
            Math.trunc(this.comm.flncttgi * 100) / 100,
            15,
            legendeTexteGini
          )
        )
      }
    }

    if (this.tp60.visible) {
      let legendeTexteTp60 = { ...legendeTexteValeurs, "text-anchor": "start" }
      // calcule de l'ordre d'affichage
      let dyDepart = 0
      let dyFrance = 0
      let dyComm = 0
      if (this.comm.tp60) {
        if (
          +this.france.tp60 > +this.comm.tp60 &&
          +this.france.tp60 > +this.depart.tp60
        ) {
          dyFrance = -20
        }
        if (
          +this.comm.tp60 > +this.france.tp60 &&
          +this.comm.tp60 > +this.depart.tp60
        ) {
          dyComm = -20
        }
        if (
          +this.depart.tp60 > +this.france.tp60 &&
          +this.depart.tp60 > +this.comm.tp60
        ) {
          dyDepart = -20
        }
        if (
          +this.france.tp60 < +this.comm.tp60 &&
          +this.france.tp60 < +this.depart.tp60
        ) {
          dyFrance = 20
        }
        if (
          +this.comm.tp60 < +this.france.tp60 &&
          +this.comm.tp60 < +this.depart.tp60
        ) {
          dyComm = 20
        }
        if (
          +this.depart.tp60 < +this.france.tp60 &&
          +this.depart.tp60 < +this.comm.tp60
        ) {
          dyDepart = 20
        }
      }

      if (this.france.tp60) {
        this.animateText(
          this.addTextTSpan(
            this.svgChart,
            this.tp60.x + dx + 4,
            scaleTp60(this.france.tp60) + dy + 0,
            this.france.nom + " :",
            Math.trunc(this.france.tp60 * 100) / 100,
            15,
            legendeTexteTp60
          )
        )
      }
      if (this.depart.tp60) {
        this.animateText(
          this.addTextTSpan(
            this.svgChart,
            this.tp60.x + dx + 4,
            scaleTp60(this.depart.tp60) + dy + 0,
            this.depart.nom + " :",
            Math.trunc(this.depart.tp60 * 100) / 100,
            15,
            legendeTexteTp60
          )
        )
      }
      if (this.comm.tp60) {
        this.animateText(
          this.addTextTSpan(
            this.svgChart,
            this.tp60.x + dx + 4,
            scaleTp60(this.comm.tp60) + dy + 0,
            this.comm.nom + " :",
            Math.trunc(this.comm.tp60 * 100) / 100,
            15,
            legendeTexteTp60
          )
        )
      }
    }

    // this.addText(this.svgChart, 0, 20, "Lecture : le revenu médian pour une personne est de " + this.comm.nvq2 + " € dans votre commune, contre " + this.france.nvq2 + " € en moyenne pour l'ensemble de la France.", {"font-size": "16px"});
    //        this.addText(this.svgChart, 0, 0, "Source: Insee 2012 - Compas-tis 2016", {"font-size": "16px"});
  }

  init() {
    this.svg = d3.select(this.ref).selectAll("svg").remove()
    this.svg = d3
      .select(this.ref)
      .append("svg")
      .attr("width", this.svgWidth)
      .attr("height", this.svgHheight)
      .attr("viewBox", "0 0 " + this.viewportWidth + " " + this.viewportHeight)
    // .attr("preserveAspectRatio", "xMinYMin meet")
    this.svgChart = this.svg.append("g")
    this.initInnerChart()
    this.animate()
  }
}
