import React from "react"

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  Text,
  Stack,
  List,
  ListItem,
} from "@chakra-ui/core"

function DisplayModal({ codzone }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} size="sm">
        {codzone ? "Comprendre les valeurs du graphique" : "Notre méthodologie"}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comprendre les valeurs du graphique</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5} fontSize="sm">
              <Text>
                Nous utilisons les données Filosofi sur les niveaux de vie de
                l'année 2020, pour la France métropolitaine et pour une unité de
                consommation (l'équivalent d'une personne seule). Ces revenus
                comprennent les prestations sociales et ils tiennent compte des
                impôts directs payés. Pour les communes de petite taille,
                certaines données ne sont pas disponibles.
              </Text>
              <Text>
                {" "}
                Le niveau de vie médian est celui qui partage l’effectif en deux
                : autant gagnent moins et autant gagnent plus.{" "}
                <Text as="em">10 % les plus pauvres</Text> indique la valeur
                maximale perçue par cette tranche,{" "}
                <Text as="em">10 % les plus riches</Text> la valeur minimale.
              </Text>
              <Text>
                {" "}
                L’indice de Gini compare la distribution des revenus dans le
                territoire à une distribution égale. Plus il est proche de zéro,
                plus on est proche de l’égalité. Plus il est proche de 1, plus
                les revenus sont inégaux. Le taux de pauvreté est calculé au
                seuil de 60 % du niveau de vie médian.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
export default DisplayModal
