import React, { useState, useEffect } from "react"
import _ from "lodash"
import { Text, Select, Flex, Image, Box } from "@chakra-ui/core"
import startImg from "../../images/37255.jpg"
import departements from "./dataset/dep.json"
import communes from "./dataset/terr.json"

function SelectComm({
  setCodzone,
  codzone,
  codeDepSelected,
  isFirstDisplayed,
  setFirstDisplayed,
}) {
  const localCommunes = []
  if (codeDepSelected) {
    _.forEach(communes, comm => {
      if (comm.dep === codeDepSelected) localCommunes.push(comm)
    })
  }
  return (
    <>
      <Text>Sélectionnez une commune</Text>
      <Select
        value={codzone || "__"}
        onChange={item => {
          if (!isFirstDisplayed) {
            setFirstDisplayed(true)
          }
          setCodzone(item.target.value)
        }}
      >
        <option value={"__"} key={"__"}></option>
        {_.filter(communes, com => com.dep === codeDepSelected).map(com => (
          <option value={com.com} key={com.com}>
            {com.nom}
          </option>
        ))}
      </Select>
    </>
  )
}

function StartSelect({
  codzone,
  setCodzone,
  setFirstDisplayed,
  isFirstDisplayed
}) {
  const [depSelected, setSelectedDep] = useState(undefined)
  useEffect(() => {
    if (!depSelected || !codzone || !codzone.startsWith(depSelected)) {
      setCodzone(undefined)
    }
  }, [depSelected])

  return (
    <Flex flexDirection="row" alignItems="center">
      <Image src={startImg} htmlWidth="10%" />

      <Flex flexDirection="column" flexGrow="1" mr={4}>
        <Text fontWeight="800">
          Découvrez les niveaux de vie pour votre territoire
        </Text>
        <Select
          placeholder="Sélectionnez un département"
          onChange={item => setSelectedDep(item.target.value)}
          value={depSelected}
        >
          {_.map(departements, dep => (
            <option value={dep.code} key={dep.code}>
              {dep.nom}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex flexDirection="column" flexGrow="1">
        <SelectComm
          codzone={codzone}
          setCodzone={setCodzone}
          codeDepSelected={depSelected}
          setFirstDisplayed={setFirstDisplayed}
          isFirstDisplayed={isFirstDisplayed}
        />
      </Flex>
    </Flex>
  )
}

export default function Home({
  codzone,
  setCodzone,
  selectContainer,
  headerContainer,
}) {
  const [isFirstDisplayed, setFirstDisplayed] = useState(false)
  useEffect(() => {
    if (isFirstDisplayed) {
      window.scrollTo({
        top:
          selectContainer.current.offsetTop -
          headerContainer.current.offsetHeight,
        behavior: "smooth",
      })
    }
  }, [isFirstDisplayed, headerContainer, selectContainer])
  return (
    <Flex mr={10} ml={10} ref={selectContainer}>
      <StartSelect
        setCodzone={setCodzone}
        codzone={codzone}
        setFirstDisplayed={setFirstDisplayed}
        isFirstDisplayed={isFirstDisplayed}
      />
    </Flex>
  )
}
